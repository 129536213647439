import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ThemeLayout from '../../layouts/layout';
import SignUp from '../../components/organisms/Auth/SignUp';

const SignUpAuthPage = ({ data: { siteConfig } }) => (
  <ThemeLayout>
    <SignUp GDPRCountry={siteConfig.GDPRCountry} isModal={false} />
  </ThemeLayout>
);

SignUpAuthPage.propTypes = {
  data: PropTypes.shape().isRequired,
};
SignUpAuthPage.defaultProps = {};

export default SignUpAuthPage;

export const query = graphql`
  query SignUpAuthPageQuery {
    siteConfig {
      GDPRCountry
    }
  }
`;
